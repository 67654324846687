import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Icon,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  Breadcrumb as BreadcrumbType,
  LinkDataProps,
  SubNavLayoutConfig,
} from '@tb/common';

const SubLinkComponent = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props: LinkProps, ref) => {
    const router = useRouter();
    const active = router.asPath === props.href;

    return (
      <Link variant={active ? 'nav_active' : 'nav'} ref={ref} {...props} />
    );
  },
);

SubLinkComponent.displayName = 'SubLinkComponent';

const SubNavLink = ({
  label,
  path,
  breadCrumb,
  noOverflow,
}: LinkDataProps & {
  breadCrumb?: boolean;
}) => {
  // const router = useRouter();

  let LinkComponent;
  if (!breadCrumb) {
    LinkComponent = SubLinkComponent;
  } else {
    LinkComponent = BreadcrumbLink;
  }
  if (path) {
    return (
      <NextLink data-testid={label} href={path} passHref legacyBehavior>
        <LinkComponent
          style={
            noOverflow
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : undefined
          }
        >
          {label}
        </LinkComponent>
      </NextLink>
    );
  }

  return <LinkComponent>{label}</LinkComponent>;
};

export const SubNavLayoutV2 = ({
  heading,
  breadCrumbs,
  breadCrumbPlaceholder,
  linkPlaceholder,
  links,
  contextMenu,
}: SubNavLayoutConfig) => {
  const [crumbs, setCrumbs] = useState<BreadcrumbType[]>(
    breadCrumbPlaceholder || [],
  );
  const [renderedLinks, setRenderedLinks] = useState<LinkDataProps[]>(
    linkPlaceholder || [],
  );

  const { colorMode } = useColorMode();

  useEffect(() => {
    const fetchCrumbs = async () => {
      const result = await breadCrumbs();
      setCrumbs(result);
    };

    fetchCrumbs();
  }, [breadCrumbs]);

  useEffect(() => {
    const fetchLinks = async () => {
      const result = await links();
      setRenderedLinks(result);
    };

    fetchLinks();
  }, [links]);

  return (
    <VStack
      w={'100%'}
      align={'stretch'}
      px={'60px'}
      bg={colorMode == 'light' ? 'white' : 'gray.700'}
      shadow={'base'}
      pt={4}
    >
      <Flex
        borderStyle="solid"
        borderColor="brand.hold"
        bg={colorMode == 'light' ? 'white' : 'gray.700'}
        alignItems={'center'}
        gap={1}
        overflowX="auto"
        justifyContent={'space-between'}
        w={'100%'}
      >
        {crumbs.length > 0 && (
          <Breadcrumb spacing={2} separator={'/'}>
            {renderedLinks.map((breadcrumb, idx) => {
              const { label, path, noOverflow } = breadcrumb;
              return (
                <BreadcrumbItem key={`${label}-${idx}`}>
                  <SubNavLink
                    path={path}
                    label={label}
                    breadCrumb
                    noOverflow={noOverflow}
                  />
                </BreadcrumbItem>
              );
            })}

            {crumbs.map((breadcrumb, idx) => {
              const { label } = breadcrumb;
              if ('options' in breadcrumb) {
                const { options, noOverflow } = breadcrumb;
                return (
                  <BreadcrumbItem key={`${label}-${idx}`}>
                    <Menu>
                      <MenuButton
                        as={BreadcrumbLink}
                        style={
                          !noOverflow
                            ? {
                                minWidth: '100px',
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }
                            : { whiteSpace: 'nowrap' }
                        }
                      >
                        {label}
                        {breadcrumb.options.length > 0 && (
                          <ChevronDownIcon fontSize={'2xl'} mx={1} />
                        )}
                      </MenuButton>
                      {breadcrumb.options.length > 0 && (
                        <Portal>
                          <MenuList
                            zIndex={1000}
                            overflowY={'auto'}
                            maxHeight={'70vh'}
                          >
                            {options.map(({ label, path }, idx) => {
                              if (path) {
                                return (
                                  <NextLink
                                    key={`${label}-${idx}`}
                                    href={path}
                                    passHref
                                    legacyBehavior
                                  >
                                    <MenuItem as={'a'}>{label}</MenuItem>
                                  </NextLink>
                                );
                              }
                              return (
                                <MenuItem key={`${label}-${idx}`}>
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Portal>
                      )}
                    </Menu>
                  </BreadcrumbItem>
                );
              }
              const { path } = breadcrumb;
              return (
                <BreadcrumbItem key={`${label}-${idx}`}>
                  <SubNavLink path={path} label={label} breadCrumb />
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        )}

        {/* <Flex alignItems={'center'} gap={8} fontSize="sm">
          {renderedLinks.map((breadcrumb, idx) => {
            const { label, path, noOverflow } = breadcrumb;
            return (
              <SubNavLink
                key={`${label}-${idx}`}
                path={path}
                label={label}
                noOverflow={noOverflow}
              ></SubNavLink>
            );
          })}
        </Flex> */}
        <Flex>
          {!!contextMenu && (
            <>
              <Menu preventOverflow={true}>
                <MenuButton
                  as={Button}
                  sx={{
                    color: 'complete.500',
                    bg: 'brand.white',
                    border: '1px solid',
                    borderColor: 'complete.500',
                    height: 7,
                  }}
                  rightIcon={<Icon fontSize="lg" as={ChevronDownIcon} />}
                >
                  Actions
                </MenuButton>
                <MenuList>
                  {contextMenu.map((item, idx) => {
                    return (
                      <MenuItem key={idx} onClick={item.onClick}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              <Divider orientation="vertical" />
            </>
          )}
        </Flex>
      </Flex>
      {heading}
    </VStack>
  );
};

import { chakra, ChakraComponent, ChakraProps } from '@chakra-ui/react';
import { Theme } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import type {
  ColDef,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  IDetailCellRendererParams,
  IServerSideDatasource,
  RowModelType,
  RowSelectedEvent,
  RowStyle,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, {
  DetailedHTMLProps,
  ElementType,
  HTMLAttributes,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import '~/utils/enterpriseGrid';
export const NAV_HEIGHT_OFFSET = '250px';
export const PROJECT_TABS_VIEW_OFFSET = '452px';
export const FULLSCREEN_TABS_VIEW_OFFSET = '200px';

export type StyledRow = StyledComponent<
  {
    theme?: Theme | undefined;
    as?: ElementType<any> | undefined;
  },
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;

type DataGridProps<T extends Record<string, unknown>> = {
  model: DataGridModel<T>;
};

export const DataGrid = <T extends Record<string, unknown>>(
  props: DataGridProps<T>,
) => {
  const {
    rows,
    columns,
    gridRef,
    containerStyle,
    gridStyle,
    onFirstRender,
    defaultColDef,
    rowStyles,
    onRowSelected,
    onGridSizeChanged,
    detailCellRenderer,
    multiSelect,
    rowModelType,
    treeData,
    getSSRGroupKey,
    isSSRGroup,
    autoColDef,
    onGridReady,
    styledRow: StyledRow,
    ...rest
  } = props.model;

  return (
    <div className="ag-theme-alpine" style={gridStyle}>
      <StyledRow>
        <AgGridReact
          {...rest}
          rowSelection={multiSelect ? 'multiple' : 'single'}
          rowHeight={rest.rowHeight || 58}
          tooltipShowDelay={100}
          containerStyle={containerStyle}
          rowData={rows}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          ref={gridRef}
          onFirstDataRendered={onFirstRender}
          rowStyle={rowStyles}
          onRowSelected={onRowSelected}
          onGridSizeChanged={onGridSizeChanged}
          detailCellRenderer={detailCellRenderer}
          rowModelType={rowModelType}
          treeData={treeData}
          getServerSideGroupKey={getSSRGroupKey}
          isServerSideGroup={isSSRGroup}
          autoGroupColumnDef={autoColDef}
          onGridReady={onGridReady}
          enableGroupEdit={true}
          suppressMenuHide={true}
        />
      </StyledRow>
    </div>
  );
};

// Extending Record<string, unknown> here will enforce that Type T is an object with string keys
export interface DataGridModel<T extends Record<string, unknown>>
  extends GridOptions<T> {
  gridRef: React.RefObject<AgGridReact<T>>;
  containerStyle: React.CSSProperties;
  gridStyle: React.CSSProperties;
  onFirstRender: () => void;
  defaultColDef: ColDef;
  rowStyles?: RowStyle;
  onRowSelected?: (event: RowSelectedEvent<T>) => void;
  onGridSizeChanged: (event: GridSizeChangedEvent<T>) => void;
  detailCellRenderer?: (
    params: IDetailCellRendererParams<T>,
  ) => React.ReactNode;
  rows: T[];
  columns: ColDef[];
  multiSelect?: boolean;
  rowModelType?: RowModelType;
  treeData?: boolean;
  getSSRGroupKey?: (data: T) => string;
  isSSRGroup?: (data: T) => boolean;
  autoColDef?: ColDef;
  onGridReady?: (event: GridReadyEvent<T>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styledRow: ChakraComponent<StyledRow, any>;
  shadow?: ChakraProps['shadow'];
}

type VariantKeys = 'condensed' | 'regular' | 'relaxed' | 'super-condensed';

export interface DataGridConfig<T extends Record<string, unknown>>
  extends GridOptions<T> {
  columns: ColDef<T>[];
  rows: T[];
  onRowSelected?: (event: RowSelectedEvent<T>) => void;
  rowStyles?: RowStyle;
  defaultColDef?: ColDef;
  onFirstRender?: () => void;
  gridStyle?: React.CSSProperties;
  detailCellRenderer?: (
    params: IDetailCellRendererParams<T>,
  ) => React.ReactNode;
  multiSelect?: boolean;
  // The below properties are related to leveraging Server Side Rendered rows
  // plus a tree model
  // [Link](https://www.ag-grid.com/react-data-grid/server-side-model/)
  ssrTree?: {
    getSSRGroupKey: (data: T) => string;
    isSSRGroup: (data: T) => boolean;
    datasource?: IServerSideDatasource;
  };
  autoColDef?: ColDef;
  rowStyleOverride?: StyledRow;
  variant?: VariantKeys;
  ssr?: boolean;
  shadow?: ChakraProps['shadow'];
}

const defaultRowStyleOverride = styled.div`
  height: inherit;
  width: 100%;
  .ag-root-wrapper {
    border-radius: 8px;
  }
  .ag-cell {
    height: 100%;
  }
  .ag-row {
    /* border-width: 0px !important; */
    cursor: pointer;
  }
  .ag-header-row {
    background: #f9fafb;
  }
  .ag-row-odd {
    /* background-color: #fafafa; */
    background-color: #f9fafb;
  }
`;

const condensedRowStyleOverride = styled(defaultRowStyleOverride)`
  height: inherit;
  width: 100%;
  .ag-root-wrapper {
    border-radius: 8px;
  }
  .ag-row {
    /* border-width: 0px !important; */
    cursor: pointer;
  }
  .ag-header-row {
    background: #f9fafb;
  }
  .ag-cell {
    height: 38px;
    padding: 0 12px;
  }
  .ag-row-odd {
    /* background-color: #fafafa; */
    background-color: #f9fafb;
  }
`;

const superCondensedRowStyleOverride = styled(defaultRowStyleOverride)`
  height: inherit;
  width: 100%;
  .ag-root-wrapper {
    border-radius: 8px;
  }
  .ag-row {
    /* border-width: 0px !important; */
    cursor: pointer;
  }
  .ag-header-row {
    background: #f9fafb;
  }

  .ag-cell {
    height: 28px;
  }

  .ag-row-odd {
    /* background-color: #fafafa; */
    background-color: #f9fafb;
  }
  .ag-theme-alpine {
    --ag-selected-row-background-color: unset;
    --ag-row-height: 30px;
  }

  .ag-popup-editor,
  .ag-right-select {
    top: 61px;
    height: unset;
  }

  .ag-rich-select-list {
    height: unset;
  }
`;

const VARIANT_CONFIG: Record<
  VariantKeys,
  {
    height: number;
    headerHeight?: number;
    rowStyle: StyledRow;
  }
> = {
  'super-condensed': {
    height: 30,
    headerHeight: 30,
    rowStyle: styled(superCondensedRowStyleOverride)``,
  },
  condensed: {
    height: 40,
    headerHeight: 40,
    rowStyle: styled(condensedRowStyleOverride)``,
  },
  regular: {
    height: 48,
    rowStyle: styled(defaultRowStyleOverride)``,
  },
  relaxed: {
    height: 56,
    rowStyle: styled(defaultRowStyleOverride)``,
  },
};

export function useDataGrid<T extends Record<string, unknown>>({
  columns,
  rows,
  onRowSelected,
  rowStyles,
  defaultColDef,
  onFirstRender,
  gridStyle,
  detailCellRenderer,
  multiSelect,
  ssrTree,
  autoColDef,
  rowStyleOverride,
  shadow,
  ssr,
  variant = 'relaxed',
  ...rest
}: DataGridConfig<T>): DataGridModel<T> {
  const gridRef = useRef<AgGridReact<T>>(null);
  const DEFAULT_STYLE = { width: '100%', height: '100%' };
  const _containerStyle = useMemo(
    () => gridStyle || DEFAULT_STYLE,
    [gridStyle],
  );
  const _gridStyle = useMemo(() => gridStyle || DEFAULT_STYLE, [gridStyle]);

  const styledRow = useMemo(
    () =>
      chakra(rowStyleOverride || VARIANT_CONFIG[variant].rowStyle, {
        baseStyle: {
          '.ag-root-wrapper': {
            shadow,
          },
        },
      }),
    [shadow],
  );

  const rowHeight = VARIANT_CONFIG[variant].height;
  const headerHeight = VARIANT_CONFIG[variant].headerHeight;

  const _onFirstRender = useCallback(() => {
    onFirstRender && onFirstRender();
    gridRef.current?.api?.sizeColumnsToFit();
  }, []);

  const _defaultColDef = useMemo<ColDef>(() => {
    return (
      defaultColDef || {
        resizable: false,
        filter: true,
        sortable: true,
        icons: { menu: '<i class="ag-icon ag-icon-group"/>' },
        menuTabs: ['filterMenuTab'],
      }
    );
  }, []);

  const onGridSizeChanged = useCallback(() => {
    gridRef.current?.api?.sizeColumnsToFit();
  }, []);

  const _rowModelType: RowModelType = ssr ? 'serverSide' : 'clientSide';
  const _treeData = ssrTree ? true : false;

  return {
    rows,
    columns,
    gridRef,
    containerStyle: _containerStyle,
    gridStyle: _gridStyle,
    onFirstRender: _onFirstRender,
    defaultColDef: _defaultColDef,
    rowStyles,
    onRowSelected,
    onGridSizeChanged,
    detailCellRenderer,
    multiSelect,
    rowModelType: _rowModelType,
    treeData: _treeData,
    getSSRGroupKey: ssrTree?.getSSRGroupKey,
    isSSRGroup: ssrTree?.isSSRGroup,
    autoColDef,
    styledRow,
    rowHeight,
    headerHeight,
    ...rest,
  };
}

import { useColorModeValue } from '@chakra-ui/react';

export const useColorModeStuff = () => {
  const BRIGHT_GREEN = 'complete.100';
  const GREEN = 'complete.500';
  const DARK_GREEN = 'complete.300';

  //used
  const colorModeGreen = useColorModeValue(GREEN, BRIGHT_GREEN);
  const colorModeBigLogo = useColorModeValue(
    '/tb_logo.svg',
    '/tb_logo_dark.svg',
  );
  const colorModeSmallLogo = useColorModeValue(
    '/tb_logo_small.svg',
    '/tb_logo_small_dark.svg',
  );

  const colorModeSaturation5 = useColorModeValue('', 'saturate(5)');
  const colorModeSaturation2 = useColorModeValue('', 'saturate(2)');

  //unused
  const colorModeGrayBg = useColorModeValue('#efefef', 'gray.800');
  const colorModeBorder = useColorModeValue('#000', BRIGHT_GREEN);
  const colorModeBlackAndWhite = useColorModeValue('#000', '#fff');

  const colorModeGreenHover = useColorModeValue(DARK_GREEN, GREEN);
  const colorModeBrightness = useColorModeValue('', 'brightness(2)');
  const colorModeInputBg = useColorModeValue('white', 'darkblue.700');

  const colorModeTabBottomBorder = useColorModeValue(GREEN, BRIGHT_GREEN);
  const colorModeBg = useColorModeValue('#efefef', 'gray.800');

  return {
    colorModeGrayBg,
    colorModeBorder,
    colorModeBlackAndWhite,
    colorModeGreen,
    colorModeGreenHover,
    colorModeSaturation2,
    colorModeSaturation5,
    colorModeInputBg,
    colorModeBigLogo,
    colorModeSmallLogo,
    colorModeTabBottomBorder,
    colorModeBg,
    colorModeBrightness,
  };
};
